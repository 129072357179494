(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbRespBlockLib"), require("mobxReact"), require("sbJsExtends"), require("ReactDOM"), require("gsap"), require("sbInternalMsgBus"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-racing-sports-blocks", ["React", "mobx", "sbRespBlockLib", "mobxReact", "sbJsExtends", "ReactDOM", "gsap", "sbInternalMsgBus"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-racing-sports-blocks"] = factory(require("React"), require("mobx"), require("sbRespBlockLib"), require("mobxReact"), require("sbJsExtends"), require("ReactDOM"), require("gsap"), require("sbInternalMsgBus"));
	else
		root["sb-responsive-racing-sports-blocks"] = factory(root["React"], root["mobx"], root["sbRespBlockLib"], root["mobxReact"], root["sbJsExtends"], root["ReactDOM"], root["gsap"], root["sbInternalMsgBus"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__21__) {
return 